import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/scopes';

    async rePushCommitment(id) {
        const url = `${this.endpointRoute}/${id}/re-push-commitment`;

        return this.asJson().post(url, {}).then(getData);
    }

    async pushCommitmentStepOne(id, data) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-one`;

        return this.asJson().post(url, data).then(getData);
    }

    async pushCommitmentStepTwoSetup(id, params) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-two`;

        return this.asJson().get(url, {params}).then(getData);
    }

    async pushCommitmentStepTwoSubmit(id, data) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-two`;

        return this.asJson().post(url, data).then(getData);
    }

    async pushCommitmentStepTwoAddDocument(id, data, params) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-two/add-document`;

        return this.asJson().post(url, data, {params}).then(getData);
    }

    async pushCommitmentStepThreeSetup(id) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-three`;

        return this.asJson().get(url).then(getData);
    }

    async pushCommitmentStepThreeSubmit(id, data) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-three`;

        return this.asJson().post(url, data).then(getData);
    }

    async pushCommitmentStepFour(id) {
        const url = `${this.endpointRoute}/${id}/push-commitment/step-four`;

        return this.asJson().get(url).then(getData);
    }

    async uploadTaskDocuments(id, data) {
        const url = `${this.endpointRoute}/${id}/upload-task-documents`;

        return this.asFormData().post(url, data).then(getData);
    }

    async downloadPackage(id, params) {
        const url = `${this.endpointRoute}/${id}/download-package`;

        return this.asJson().get(url, {params}).then(getData);
    }
})();
